import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"

export const DoublePanelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
  overflow: hidden;

  @media ${device.tablet} {
    flex-direction: row;
  }

  .cover-svg {
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: block;
  }

  .vic-illustration {
    margin-bottom: 45px;

    @media ${device.tablet} {
      margin-top: 140px;
      /* width: 384px; */
      width: 115%;
    }

    @media ${device.desktop} {
      margin-top: 240px;
      /* width: 524px; */
      /* height: 277px; */
      width: 118%;
    }

    @media ${device.large} {
      /* width: 606px; */
      /* height: 320px; */
      width: 120%;
    }
  }
  .nsw-illustration {
    margin-bottom: 20px;
    width: 70%;
    margin-left: auto;
    margin-top: 10px;
    @media ${device.tablet} {
      width: 90%;
      margin-top: 20px;

      &.nsw-landing {
        margin-left: 0;
      }
    }

    @media ${device.desktop} {
      margin-top: 50px;
      &.nsw-landing {
        margin-top: 70px;
      }
    }
  }
`
export const LeftPanelContainer = styled.div`
  background: ${props =>
    props.leftColor ? props.leftColor : props.nsw ? "#C0DFF7" : "#9be6af"};
  transition: background 0.8s;
  width: 100%;
  margin-bottom: 4px;
  @media ${device.tablet} {
    margin-bottom: 0px;
    border-right: 2px solid white;
    flex-basis: 50%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-start; */
  }
`

export const RightPanelContainer = styled(LeftPanelContainer)`
  background: ${props =>
    props.rightColor ? props.rightColor : props.nsw ? "#D3E9F9" : "#C0DFF7"};
  flex-grow: 1;
  @media ${device.tablet} {
    justify-content: flex-start;
    border-left: 2px solid white;
  }
`
export const PanelWrapper = styled.div`
  position: relative;
  width: 100%;

  @media ${device.tablet} {
    max-width: 585px;
  }

  .transitionGroup {
    height: 100%;
    width: 100%;
  }
`
export const LeftPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  ${props =>
    props.half &&
    `
    padding-bottom: 55px;
    position:relative;
    :before {
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100vw;
        height:100%;
        background:#D3E9F9;
      }

      > * {
        z-index:1;
      }
    }
    
    :first-of-type {
      overflow:visible;
      :after {
        content:'';
        position:absolute;
        bottom:0;
        left:0;
        width:100vw;
        background:white;
        height:3px;
      }
    }

    :last-of-type {
      :before {
        background:#E6F2FC;
      }
    }
  `};

  @media ${device.tablet} {
    height: 100%;
    height: ${props => props.half && "auto"};
    min-height: ${props => props.half && "50%"};
  }

  @media ${device.desktop} {
    padding: 63px;
    padding-left: 15px;
  }
`

export const RightPanelContent = styled(LeftPanelContent)`
  padding: ${props => props.noPadding && 0} !important;

  @media ${device.desktop} {
    padding-left: 64px;
  }

  > a {
    width: 100%;
    display: block;

    h3 {
      width: 100%;
    }
  }
`

export const PanelTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 18px;
  line-height: 120%;
  display: ${props => props.hideMobile && "none"};
  @media ${device.tablet} {
    display: block;
  }
  @media ${device.desktop} {
    font-size: 40px;
    margin-bottom: 20px;
    margin-bottom: ${props => props.noLine && "15px"};
  }

  span {
    background-image: linear-gradient(to right, black, black);
    background-position: 0 92%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-image: ${props => props.noLine && "none"};
  }

  svg {
    height: 16px;
    width: 38px;
    margin-left: 6px;

    @media ${device.desktop} {
      width: 47px;
      height: 20px;
      margin-left: 12px;
    }
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 16px;
    margin-bottom: 20px;
    width: 80%;
    line-height: 140%;
    color: #111111;

    &.hideMobile {
      display: none;
    }

    @media ${device.tablet} {
      display: block !important;
      width: 98%;
    }
    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  p.small {
    font-size: 12px;
    margin-bottom: 5px;

    @media ${device.tablet} {
      margin-top: 100px;
    }
  }

  p.underline {
    text-decoration: underline;
    font-size: 18px;
    margin-bottom: 0;
  }
`
export const PanelTextWYSY = styled.div`
  p {
    font-size: 12px;
    margin-bottom: 5px;

    @media ${device.tablet} {
      margin-top: 100px;
      :last-child {
        margin-top: 0;
      }
    }
  }

  a {
    text-decoration: underline;
    font-size: 18px;
    margin-bottom: 0;
  }
`
